import {useScrollFadeIn} from '../hooks/useScrollFadeIn';
import { PageContainer, PageTitle } from '../components/PageComponents';
import { WebLink } from '../components/WebpageBlocks';
import HEES from '../img/HEES.png'
import HASb from '../img/HASb.png'
import plainteraction from '../img/plainteraction.png'

import '../style/skills.css'
export function Works() {
    const animatedItem = {
        0: useScrollFadeIn('up', 0.6, 0.2),
        1: useScrollFadeIn('up', 0.6, 0.8),
        2: useScrollFadeIn('right', 0.6, 0.8),
    };
    return(
        <PageContainer justifyContent='left' alignItems='start'>
            <PageTitle {...animatedItem[0]}>What I made...</PageTitle>
            <WebLink imageSrc={HEES} title='교내 동아리 지원 관리 서비스, HEES' description='교내 동아리 지원 접수 및 관리를 위한 웹페이지. React.js를 통해 프론트엔드를 구축하였으며, 백엔드 개발자와의 협업으로 FastAPI를 통해 백엔드를 구축하였습니다. PC환경에서 정상 동작합니다.(현재 백엔드 서버 운영 중단으로 사용이 어려움)' link='https://heesfront.pages.dev/login'/>
            <WebLink imageSrc={HASb} title='교내 수강신청 인원 조회 서비스' description='교내 수강신청을 통한 강의별 인원 조회를 위한 웹페이지. React.js를 통해 프론트엔드를 구축하였으며, 백엔드는 FastAPI를 통해 구축되었습니다. 풀스택 개발을 담당하여 제작되었습니다. PC, 모바일 환경 모두 작동합니다.' link='https://hasclassmatching.com'/>
            <WebLink imageSrc={plainteraction} title='Canvas animation list' description='HTML5 canvas 기능을 활용한 애니메이션을 나열한 웹페이지. Canvas를 통해 웹 상에서 다양한 애니메이션 구현이 가능함을 보여줍니다. React.js를 활용해 제작되었습니다.' link='https://plainteraction.pages.dev/'/>
        </PageContainer>
    )
}