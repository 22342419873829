import '../style/contact.css';
import github from '../img/github-icon.png'
import { Footer } from '../components/Footer';
import { ContactContainer } from '../components/ContactsComponents';

export function Contact() {
    return(
        <center className='fullpage' id='black'>
            <h1 id="contacttitle">Contact</h1>
            <ContactContainer marginTop='15vh'>
                <div>
                    <h3 id="contactsubtitle">Email</h3>
                    <p id="contactcontent">hiseyong1008@gmail.com</p>
                </div>
                <div>
                    <h3 id="contactsubtitle">Location</h3>
                    <p id="contactcontent">Seoul, Republic of Korea</p>
                </div>
            </ContactContainer>
            <ContactContainer>
                <div>
                    <h3 id="contactsubtitle">Github</h3>
                    <a href="https://github.com/hiseyong"><img src={github} id='githubicon'/></a>
                </div>
            </ContactContainer>
            <Footer />
        </center>
    )
}