import styled from 'styled-components';

export const PageContainer = styled.div`
  margin-top: ${(props) => props.marginTop || '10vh'};
  margin-left: ${(props) => props.marginLeft || '5vw'};
  margin-right: ${(props) => props.marginRight || '5vw'};
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justifyContent || 'center'};
  align-items: ${(props) => props.alignItems || 'center'};
  min-height: 50vh;
`;

export const PageTitle = styled.h1`
  color: ${props => props.color || 'black'};
  font-family: 'SUITE', sans-serif;
  font-weight: 800;
  font-size: 3rem;
  padding-left: 5vw;
  padding-right: 5vw;
`;

export const PageText = styled.h1`
  color: ${props => props.color || 'black'};
  font-family: 'SUITE', sans-serif;
  font-weight: ${props => props.fontWeight || '500'};
  font-size: ${props => props.fontSize || '3rem'};
  padding-left: 5vw;
  padding-right: 5vw;
  width: 100%;
  text-align: ${props => props.position || 'center'};
  margin-top: ${props => props.margin || '23.45px'};
  margin-bottom: ${props => props.margin || '23.45px'};
  padding: 0;
`;

