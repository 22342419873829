import './style/general.css'
// import {SectionsContainer, Section} from 'react-fullpage';
import { Greeting } from './pages/Greeting';
import { Contact } from './pages/Contact';
import { Works } from './pages/Works';
import { Introducing } from './pages/Introducing';
import AnimatedCursor from "react-animated-cursor"

function App() {

  return (
    <div className="App">
      <AnimatedCursor
      innerSize={20}
      outerSize={20}
      color='187, 2, 111'
      outerAlpha={0.2}
      innerScale={0.7}
      outerScale={5}
      clickables={[
        'a',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
        '.link'
      ]}
    />
      <h5 id='nametag'>@hiseyong</h5>
      <Greeting/>
      <Introducing/>
      <Works/>
      <Contact/>
    </div>
  );
}

export default App;