import styled from 'styled-components';

// Styled components
export const ContactSubtitle = styled.span`
  color: white;
  font-family: 'SUITE', sans-serif;
  font-size: 30px;
  padding-left: 5vw;
  padding-right: 5vw;
`;

export const ContactContent = styled.span`
  color: white;
  font-family: 'SUITE', sans-serif;
  font-size: 20px;
  padding-left: 5vw;
  padding-right: 5vw;
`;

export const ContactContainer = styled.span`
  margin-top: ${(props) => props.marginTop || '10vh'};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;