import '../style/greeting.css'
import { useState, useEffect } from 'react';
export function Greeting() {
  const [title, setTitle] = useState('');
  const [count, setCount] = useState(0);
  const completionWord = 'Imagination Becomes Reality.';
  const delaygap = 20;

  useEffect(() => {
    const typingInterval = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount < completionWord.length) {
          setTitle(completionWord.slice(0, prevCount + 1));
        } else if (prevCount >= completionWord.length + delaygap) {
          setTitle('');
          return 0;
        }
        return prevCount + 1;
      });
    }, 100);

    return () => {
      clearInterval(typingInterval);
    };
  }, [completionWord, delaygap]);
    return(
        <center className='TitlePage'>
            <h1 id="maintitle">{title}</h1>
            <div class="arrows"></div>
        </center>
    )
}