import { PageContainer, PageText, PageT } from "../components/PageComponents"
import { useScrollFadeIn } from "../hooks/useScrollFadeIn"
export function Introducing() {
    const animatedItem = {
        0: useScrollFadeIn('up', 0.6, 0.2),
        1: useScrollFadeIn('up', 1.2, 0.5),
        2: useScrollFadeIn('up', 1.2, 0.5),
        3: useScrollFadeIn('up', 1.2, 0.5),
    };
    return(
        <PageContainer justifyContent='center' alignItems='start' marginTop='0' marginLeft='5vw' marginright='5vw'>
            <PageText {...animatedItem[0]} fontSize='3rem' position='center'>상상을 구현하는 개발자, 안세용입니다.</PageText>
            <PageText {...animatedItem[1]} fontSize='1.8rem' position='left'>Skills: React.js, Next.js, FastAPI, Express.js, etc...</PageText>
            <PageText {...animatedItem[2]} fontSize='1.8rem' position='left'>Interests: Low-level programming, Web Development, Embeded System, Hardware</PageText>
            <PageText {...animatedItem[3]} fontSize='1.8rem' position='left'>Hobby: Skiing, Writing a code, Reading</PageText>
        </PageContainer>
    )
}