import React from 'react';
import styled from 'styled-components';
import { useScrollFadeIn } from '../hooks/useScrollFadeIn';

const Container = styled.div`
  display: flex;
  font-family: 'SUITE', sans-serif;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 16px;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const ImageWrapper = styled.div`
  flex: 0 0 100px;
  height: 100px;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Content = styled.div`
  padding: 16px;
  flex: 1;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 1.2rem;
  color: #333;
`;

const Description = styled.p`
  margin: 8px 0 0;
  color: #666;
`;

export const WebLink = ({ imageSrc, title, description, link }) => {
    const animatedItem = {
        0: useScrollFadeIn('up', 0.6, 0.5),
        1: useScrollFadeIn('up', 0.6, 0.8),
        2: useScrollFadeIn('right', 0.6, 0.8),
    };

  const openLink = () => {
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  return (
    <Container onClick={openLink}>
      <ImageWrapper>
        <Image src={imageSrc} alt={title} />
      </ImageWrapper>
      <Content>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Content>
    </Container>
  );
};
